<template lang="pug">
  tr.inventory-group-item
    td.name(:title="inventoryGroup.name") {{ inventoryGroup.name }}
    td(
      v-for="ota in otas"
      :key="ota.id"
      :title="get(groupedSourcesInventoryGroups, `${ota.id}.name`, '—')"
      :class="[{ inactive: isInactiveSourcesInventoryGroup(ota) }, ota.rawName]"
    )
      span.m-r-5(v-if="isInactiveSourcesInventoryGroup(ota)") ({{ $t("shop_matching.inactive") }})
      span {{ get(groupedSourcesInventoryGroups, `${ota.id}.name`, "—") }}
      Transition(
        v-if="groupedSourcesInventoryGroups[ota.id]"
        name="fade"
      )
        template(v-if="otaWithMasterShop.includes(ota.rawName)")
          AppTooltip(
            v-if="selectedMasterShops[ota.id]"
            type="success"
            icon="check-circle"
            :title="`${$t('inventory_group_matching.master_shop')}: ${selectedMasterShops[ota.id].name}`"
          )
          AppTooltip(
            v-else
            type="danger"
            icon="check-circle"
            :title="$t('inventory_group_matching.master_shop_not_selected')"
          )
    td.edit
      AppIconButton.action-button(
        :disabled="!hasEditPermission()"
        icon="edit"
        @click="$emit('edit')"
      )
    td.delete
      AppIconButton.action-button(
        :disabled="!hasEditPermission()"
        icon="trash-alt"
        @click="$emit('delete')"
      )
</template>

<script>
  // misc
  import { get, mapValues, find } from "lodash-es"
  import { toObjectBy } from "@/helpers/common"
  import { OTA_WITH_MASTER_SHOP } from "@/config/constants"

  // mixins
  import withPermissions from "@/mixins/withPermissions"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    mixins: [withPermissions],

    props: {
      inventoryGroup: Object,
      otas: Array,
      sourcesShopsByOtas: {
        type: Object,
        default: () => new Object()
      }
    },

    data() {
      return {
        otaWithMasterShop: OTA_WITH_MASTER_SHOP
      }
    },

    computed: {
      groupedSourcesInventoryGroups({ inventoryGroup }) {
        return toObjectBy("ota_id", inventoryGroup.sources_inventory_groups)
      },

      selectedMasterShops({ sourcesShopsByOtas }) {
        return mapValues(sourcesShopsByOtas, shops => {
          return find(shops, ({ master }) => master)
        })
      }
    },

    methods: {
      get,

      isInactiveSourcesInventoryGroup({ id }) {
        const sourcesInventoryGroup = this.groupedSourcesInventoryGroups[id]

        return sourcesInventoryGroup && !sourcesInventoryGroup.active
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/common.sass"

  .inventory-group-item
    +custom-table-td

    .name
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 250px

    .edit,
    .delete
      width: 15px

      .action-button
        +icon-button($default-purple)

    td
      padding: 0 10px

    .inactive
      color: $default-gray-medium
</style>
